<template>
  <v-banner
    two-line
    elevation="1"
  >
    <v-avatar
      slot="icon"
      :color="iconBackgroundColor"
      size="40"
    >
      <v-icon
        :color="iconColor"
      >
        {{ icon }}
      </v-icon>
    </v-avatar>
    <div
      v-if="message"
    >
      {{ message }}
    </div>
    <template v-if="messages">
      <div
        v-for="(item, index) in messages"
        :key="`message-${index}`"
      >
        {{ item }}
      </div>
    </template>
  </v-banner>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    messages: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    iconBackgroundColor() {
      let bg = 'deep-purple accent-4'
      if (this.type === 'info') {
        bg = 'blue accent-4'
      } else if (this.type === 'warning') {
        bg = 'yellow accent-4'
      } else if (this.type === 'check' || this.type === 'success') {
        bg = 'green accent-4'
      }
      return bg
    },
    iconColor() {
      let color = 'white'
      if (this.type === 'info') {
        color = 'white'
      } else if (this.type === 'warning') {
        color = 'black'
      } else if (this.type === 'check' || this.type === 'success') {
        color = 'white'
      }
      return color
    },
    icon() {
      let name = 'info'
      if (this.type === 'info') {
        name = 'lightbulb'
      } else if (this.type === 'warning') {
        name = 'priority_high'
      } else if (this.type === 'check' || this.type === 'success') {
        name = 'check'
      }
      return name
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
