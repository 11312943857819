<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent :title="`${platform} 버전관리 ${mode_text}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="item.version"
            label="버전"
            placeholder="버전을 입력해 주세요. ex: 'v1.0.0' or '2020-09'"
            hint="버전을 입력해 주세요. ex: 'v1.0.0' or '2020-09'"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="item.title"
            label="제목"
            placeholder="제목을 입력해 주세요"
            hint="제목을 입력해 주세요"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="mt-4"
        >
          <p class="subtitle-1">
            적용여부 (테스트 혹은 아직 배포 준비 중인경우, 해당 스토어에 업로드가 된 이후에 비활성 -> 활성으로 변경해주세요)
          </p>
          <v-switch
            v-model="item.is_activated"
            :label="(item.is_activated) ? '활성' :'비활성'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="caption"
        >
          <InformationComponent
            type="info"
            :messages="[`* 배포 준비 중인경우, 비활성으로 미리 내용을 작성하시고 이후 스토어에 퍼블리싱이 된 이후에 비활성 -> 활성으로 변경해주세요`,
                        `* '활성'으로 변경할 경우 기존 '활성' 상태의 레코드들은 전부 '비활성' 처리됩니다.`]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="mt-4"
        >
          <p class="subtitle-1">
            강제 업데이트 여부
          </p>
          <v-switch
            v-model="item.is_forced_update"
            :label="(item.is_forced_update) ? '강제 업데이트 활성' :'강제 업데이트 비활성'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="caption"
        >
          <InformationComponent
            type="info"
            :messages="[`* 배포한 앱에 문제가 생겨 강제 업데이트가 필요한 경우, 스토어에 퍼블리싱이 된 이후 '강제 업데이트 활성'으로 변경해 주세요.`,
                        `* '강제 업데이트 활성'으로 변경할 경우 기존 '활성' 상태의 레코드들은 전부 '비활성' 처리됩니다.`]"
          />
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12">
          <p class="subtitle-1">
            내용
          </p>
          <Editor
            v-model="item.content"
            class="mt-2"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-5"
      >
        <v-col
          cols="auto"
        >
          <v-btn
            v-if="mode==='add'"
            class="mx-4"
            color="primary"
            @click="saveItem"
          >
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">등록</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="primary"
            @click="updateItem"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">수정</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="error"
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="pl-2">삭제</span>
          </v-btn>
          <v-btn
            color=""
            class="mx-4"
            @click="backToListPage"
          >
            <v-icon small>
              list
            </v-icon>
            <span class="pl-2">목록으로</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet
      v-model="deleteConfirmSheetView"
      persistent
    >
      <v-sheet
        class="text-center"
        height="250px"
      >
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn
            class="mx-3"
            color="primary"
            @click="deleteItem"
          >
            <span>Y</span>
          </v-btn>
          <v-btn
            class="mx-3"
            color=""
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-form>
</template>
<script>
import Editor from '../_elements/editor/MavonEditor'
import PageTitleComponent from '../_elements/title/PageTitle'
import InformationComponent from '../_elements/textbox/Information'
import appVersionApi from './api/appVersion'

export default {
  components: {
    Editor,
    PageTitleComponent,
    InformationComponent
  },
  data() {
    return {
      valid: false,
      lazy: false,
      deleteConfirmSheetView: false,
      mode: '',
      mode_text: '',
      platform: '',
      item: {
        idx: undefined,
        title: '',
        content: '',
        version: '',
        is_activated: true,
        is_forced_update: false
      },
      rules: {
        required: (value) => !!value || '꼭 입력해주세요'
      }
    }
  },
  watch: {
    $route(to) {
      this.initPageMode(to)
    }
  },
  created() {
    this.platform = this.$route.params.platform
    this.initPageMode(this.$route)
  },
  methods: {
    initPageMode(currentRoute) {
      if (currentRoute.params.idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        this.item.idx = parseInt(currentRoute.params.idx, 10)
        this.getContent()
      }
    },
    async getContent() {
      if (this.item.idx === undefined) {
        return
      }
      const res = await appVersionApi.getItem(this.platform, this.item.idx)
      if (res.data.result === true) {
        this.item = res.data.data.item
      }
    },
    async saveItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const res = await appVersionApi.addItem(
        this.platform, this.item.is_activated, this.item.is_forced_update,
        this.item.version, this.item.title, this.item.content
      )
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async updateItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const res = await appVersionApi.updateItem(
        this.platform, this.item.idx, this.item.is_activated,
        this.item.is_forced_update, this.item.version, this.item.title, this.item.content
      )
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const res = await appVersionApi.deleteItem(this.platform, this.item.idx)
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Service.App.Platform.Versions') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/service/app/${this.platform}/verions?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
