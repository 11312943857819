<template>
  <mavon-editor
    v-model="content"
    class="markdown-editor"
    :toolbars="toolbars"
    placeholder="Write something..."
    language="en"
    :editable="isEditable"
    :subfield="isSubfield"
    :toolbars-flag="isToolbarsFlag"
    :default-open="defaultOpen"
  />
</template>

<script>
import 'mavon-editor/dist/css/index.css'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toolbars: {
        bold: true,
        italic: true,
        header: true,
        underline: true,
        strikethrough: true,
        mark: true,
        superscript: true,
        subscript: true,
        quote: true,
        ol: true,
        ul: true,
        link: true,
        imagelink: false,
        code: true,
        table: true,
        fullscreen: true,
        readmodel: true,
        htmlcode: true,
        help: true,
        undo: true,
        redo: true,
        trash: false,
        save: false,
        navigation: true,
        alignleft: true,
        aligncenter: true,
        alignright: true,
        subfield: true,
        preview: true
      }
    }
  },
  computed: {
    content: {
      get() {
        return this.value
      },
      set(content) {
        this.$emit('input', content)
      }
    },
    isEditable() {
      return !this.readonly
    },
    isSubfield() {
      return !this.readonly
    },
    isToolbarsFlag() {
      return !this.readonly
    },
    defaultOpen() {
      return this.readonly ? 'preview' : 'preview'
    }

  }
}
</script>

<style lang="scss" scoped>
.markdown-editor {
  z-index: 2;
}
</style>
