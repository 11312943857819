import { Api, makeGetUrl } from '@/libraries/api'

function getList(platform, page = 1, limit = 10, query = '') {
  const url = `/admin/app/${platform}/versions`
  const params = {
    page,
    limit,
    query
  }
  return Api().get(makeGetUrl(url, params))
}

function addItem(platform, isActivated, isForcedUpdate, version, title, content) {
  const url = `/admin/app/${platform}/versions`
  const data = {
    is_activated: isActivated,
    is_forced_update: isForcedUpdate,
    version,
    title,
    content
  }
  return Api().post(url, data)
}

function getItem(platform, idx) {
  const url = `/admin/app/${platform}/versions/${idx}`
  return Api().get(url)
}

function updateItem(platform, idx, isActivated, isForcedUpdate, version, title, content) {
  const url = `/admin/app/${platform}/versions/${idx}`
  const data = {
    is_activated: isActivated,
    is_forced_update: isForcedUpdate,
    version,
    title,
    content
  }
  return Api().put(url, data)
}

function deleteItem(platform, idx) {
  const url = `/admin/app/${platform}/versions/${idx}`
  return Api().delete(url)
}

export default {
  getList,
  getItem,
  addItem,
  updateItem,
  deleteItem
}
